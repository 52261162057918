import React from "react";
import styled from "styled-components";
import { Grid } from "@mui/material";
import Slider from "react-slick";

// shared component
import Header from "../shared/Header";
import Button from "../shared/Button";

// JSON data of the Project
import { professionalProjectData, petProjectData } from "./ProjectData";

// Slider css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// styles
const Container = styled.section`
	padding: 4rem 0;
`;

const Wrapper = styled.div`
	width: 75%;
	margin: auto;

	@media (max-width: 600px) {
		width: 80%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 90%;
	}
`;

const SliderContainer = styled.div``;

const GridContainer = styled.div`
	margin-top: 2rem;
`;

const ProjectWrapper = styled.div`
	/* background */
	backdrop-filter: blur(8px) saturate(180%);
	-webkit-backdrop-filter: blur(8px) saturate(180%);
	background-color: rgba(47, 47, 47, 0.55);
	border-radius: 0.5rem;
	border: 1px solid rgba(47, 47, 47, 0.55);
	/* display */
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	/* padding */
	padding: 1rem;
	margin: 1rem 0.5rem;

	&:hover {
		transform: scale(1.02);
		transition: all 0.3s ease-in-out;
	}
`;

const ProjectImageBox = styled.div`
	overflow: hidden;
	border-radius: 0.5rem;
`;

const ProjectImage = styled.img`
	height: 100%;
	width: 100%;
	object-fit: cover;

	${ProjectWrapper}:hover & {
		transform: scale(1.05);
		transition: all 0.3s ease-in-out;
	}
`;

const ProjectTextBox = styled.div`
	/* flex */
	flex-grow: 1;
	/* margin & padding */
	margin-top: 1.5rem;
	padding-inline: 1rem;

	@media (max-width: 600px) {
		padding-inline: 0.5rem;
		margin-top: 1.5rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		padding-inline: 0.5rem;
	}
`;

const ProjectName = styled.h3`
	/* color */
	color: var(--whiteColor);
	/* font */
	font-size: 1.3rem;
	letter-spacing: 1.5px;

	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 1rem;
	}
`;

const ProjectDescription = styled.p`
	/* color */
	color: var(--whiteColor);
	/* font */
	font-size: 1rem;
	line-height: 1.5;
	letter-spacing: 0.5px;
	/* opacity */
	opacity: 0.8;
	/* margin */
	margin: 1rem 0 2rem 0;

	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.65rem;
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 1rem;

	@media (max-width: 600px) {
		justify-content: center;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		justify-content: center;
	}
`;

export default function Cards() {
	// Slider Settings
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	return (
		<Container>
			<Wrapper>
				<Slider {...settings}>
					{/* Professional Project Container */}
					<SliderContainer>
						<Header>Professional Projects</Header>
						<GridContainer>
							<Grid
								container
								justifyContent='space-evenly'
								spacing={1}
								sx={{ display: "flex", justifyContent: "center", alignItems: "stretch", flexGrow: "1" }}
							>
								{professionalProjectData.map((item, id) => (
									<Grid
										item
										key={id}
										xs={12}
										sm={4}
										md={4}
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "stretch",
											flexGrow: "1",
										}}
									>
										<ProjectWrapper>
											<ProjectImageBox>
												<ProjectImage src={item.projectImagePath} alt={item.projectName} />
											</ProjectImageBox>
											<ProjectTextBox>
												<ProjectName>{item.projectName}</ProjectName>
												<ProjectDescription>{item.projectDescription}</ProjectDescription>
											</ProjectTextBox>

											<ButtonWrapper>
												<Button link={item.projectLink}>Explore More</Button>
											</ButtonWrapper>
										</ProjectWrapper>
									</Grid>
								))}
							</Grid>
						</GridContainer>
					</SliderContainer>
					{/* End of Professional Project  */}

					{/* Pet Project Container */}
					<SliderContainer>
						<Header>Pet Projects</Header>
						<GridContainer>
							<Grid
								container
								justifyContent='space-evenly'
								spacing={1}
								sx={{ display: "flex", alignItems: "stretch", flexGrow: "1" }}
							>
								{petProjectData.map((item, id) => (
									<Grid
										item
										key={id}
										xs={12}
										sm={4}
										md={4}
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "stretch",
											flexGrow: "1",
										}}
									>
										<ProjectWrapper>
											<ProjectImageBox>
												<ProjectImage src={item.projectImagePath} alt={item.projectName} />
											</ProjectImageBox>
											<ProjectTextBox>
												<ProjectName>{item.projectName}</ProjectName>
												<ProjectDescription>{item.projectDescription}</ProjectDescription>
											</ProjectTextBox>

											<ButtonWrapper>
												<Button link={item.projectLink}>Explore More</Button>
											</ButtonWrapper>
										</ProjectWrapper>
									</Grid>
								))}
							</Grid>
						</GridContainer>
					</SliderContainer>
					{/* End of Pet Project  */}
				</Slider>
			</Wrapper>
		</Container>
	);
}
