import React from "react";
import styled from "styled-components";

// style
const HeaderWrapper = styled.div`
	text-align: center;
	padding: 2rem 0;
`;

const HeaderText = styled.h2`
	/* color */
	color: var(--mainColor);
	/* font */
	font-family: "Reem Kufi";
	font-size: 2.5rem;
	letter-spacing: 1px;

	@media (max-width: 600px) {
		font-size: 2rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 1.4rem;
	}
`;

export default function Header({ children }) {
	return (
		<HeaderWrapper>
			<HeaderText>{children}</HeaderText>
		</HeaderWrapper>
	);
}
