import React from "react";
import styled from "styled-components";

// logo Icons
import LinkedInLogo from "../../assets/Icons/linkedinLogo.png";
import GithubLogo from "../../assets/Icons/github-logo.png";

// styles
const Container = styled.section``;

const Wrapper = styled.div`
	width: 80%;
	margin: auto;
	padding: 10rem 0 6rem 0;

	@media (max-width: 600px) {
		width: 90%;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		width: 85%;
	}
`;

const HeaderBox = styled.div`
	text-align: center;
	color: var(--mainColor);

	@media (max-width: 600px) {
		padding-inline: 1rem;
	}
`;

const HeaderText = styled.h1`
	/* font */
	font-family: "Reem Kufi";
	font-size: 4.5rem;
	letter-spacing: 1px;

	@media (max-width: 600px) {
		font-size: 2.6rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 3.5rem;
	}
`;

const SkillText = styled.p`
	/* font */
	font-size: 1.8rem;
	font-weight: 300;
	letter-spacing: 1px;
	margin-top: 1rem;

	@media (max-width: 600px) {
		font-size: 1.6rem;
		margin-top: 2rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 1.1rem;
	}
`;

const IconBox = styled.div`
	/* display */
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	/* margin */
	margin-top: 3rem;

	@media (max-width: 600px) {
		margin-top: 4rem;
	}
`;

const IconIcon = styled.img`
	height: 4.5rem;
	width: auto;
	object-fit: contain;
	cursor: pointer;
	opacity: 0.8;

	&:hover {
		transform: scale(1.1);
		opacity: 1;
		transition: all 0.2s ease-in-out;
	}
	@media (max-width: 600px) {
		height: 3.5rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		height: 3.5rem;
	}
`;

export default function Hero() {
	return (
		<Container>
			<Wrapper>
				<HeaderBox>
					<HeaderText>Hey, I'm Marko!</HeaderText>
					<SkillText>Software Architect | Game Developer</SkillText>
					<IconBox>
						<a href='https://www.linkedin.com/in/zanoski/' target='_blank' rel='noopener noreferrer'>
							<IconIcon src={LinkedInLogo} alt='Zanoshky LinkedIn link' />
						</a>
						<a href='https://github.com/zanoshky' target='_blank' rel='noopener noreferrer'>
							<IconIcon src={GithubLogo} alt='Zanoshky Github link' />
						</a>
					</IconBox>
				</HeaderBox>
			</Wrapper>
		</Container>
	);
}
