// Professional Project Image
import AppEduMatImage from "../../assets/Projects/appEduMatImage.png";
import WorkBookImage from "../../assets/Projects/workbookImage.png";
import StormolowImage from "../../assets/Projects/stormolowImage.png";
import AfterPayImage from "../../assets/Projects/afterPayImage.png";
import MedicaLandImage from "../../assets/Projects/medicaLandImage.png";
import TipicoImage from "../../assets/Projects/tipicoImage.png";
import MySugrImage from "../../assets/Projects/mySugrImage.png";
import SonImage from "../../assets/Projects/sonImage.png";

// Pet Project Image

// TO ADD NEW PROJECTS
// Add A New Project within this JSON data, it will adapt the design and add your new project on the project section
export const professionalProjectData = [
  {
    projectName: "Tipico",
    projectLink: "  https://portal-nj.tipico.us/",
    projectDescription:
      "As a Software Engineer I have individually implemented many new payments providers connected to the sports book portal, handling thousands of requests per minute.",
    projectImagePath: TipicoImage,
  },
  {
    projectName: "mySugr",
    projectLink: "https://shop.mysugr.com/",
    projectDescription:
      "As a Java Software Engineer I have solely implemented new payment provider Adyen into the parent company worth $66B (Roche). I have implemented various partners, over 20 of them: Allianz, Activision Blizzard, DKV, and Sam's Club.",
    projectImagePath: MySugrImage,
  },
  {
    projectName: "Medici Land Governance",
    projectLink: "https://www.businesswire.com/news/home/20210428005538/en/Medici-Land-Governance-Launches-Ubutaka-Piloting-a-Paperless-Land-Transfer-System-in-Rwanda",
    projectDescription:
      "As a Software Developer II I was single-handedly responsible for architecture, designing, developing, and testing components for the Government of the third world countries like Zambia and Rwanda. I was creating solutions to digitize every day to day work for its citizens. My work has secured the company long term contracts and opened new doors to grow our presence in third world countries.",
    projectImagePath: MedicaLandImage,
  },
  {
    projectName: "Ericsson",
    projectLink:
      "https://www.ericsson.com/en/blog/2021/5/cognitive-networks",
    projectDescription:
      "As Software Engineer I am permanently outsourced to Ericsson on-site in Athlone, Ireland. My day to day operations includes working for one of the main actors in the telecommunications industry, developing a self-organizing network (SON) optimization tool. Development of a self-organizing network (SON) optimization tool. Multi-vendor (Ericsson, Nokia Siemens, Huawei, and Alcatel-Lucent) and multi-technology (GSM, WCDMA, and LTE). The tool has the ability to capture large amounts of data from the network OSS, analyze it, and propose changes for performance tuning in a non-supervised way.",
    projectImagePath: SonImage,
  },
  {
    projectName: "Touchcorp Limited",
    projectLink:
      "https://www.valora.com/en/newsroom/detail/4292/battery-low-no-longer-so-lend-out-charged-battere-batteries-at-1000-valora-points-of-sale",
    projectDescription:
      "As Java/JEE Developer I communicated with customers’ development team who wanted to integrate their products into our systems. Collaboratively designing API’s on both ends, developing and integrating solution to establish REST communication between two systems, persisting the data using Hibernate into MySQL and PostgreSQL databases.",
    projectImagePath: AfterPayImage,
  },
];

// TO ADD NEW PROJECTS
// Add A New Project within this JSON data, it will adapt the design and add your new project on the project section
export const petProjectData = [
  {
    projectName: "AppEduMat",
    projectLink: "https://appedumat.zanoski.com/",
    projectDescription:
      "AppEduMat a browser game is a video game that is played via the internet using a web browser.",
    projectImagePath: AppEduMatImage,
  },
  {
    projectName: "Stormolow",
    projectLink: "https://codename-stormolow.zanoski.com/login",
    projectDescription:
      "A role-playing game based around building a small team of monsters to battle other monsters in a quest to become the best.",
    projectImagePath: StormolowImage,
  },
  {
    projectName: "WorkBook",
    projectLink: "https://workbook.zanoski.com",
    projectDescription:
      "A WorkBook is a dashboard management tool that helps to track all your relevant finance KPIs and allows for effective cash management.",
    projectImagePath: WorkBookImage,
  },
];
