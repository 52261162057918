import React from "react";
import styled from "styled-components";

// material icons
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ButtonTheme = styled.button`
	/* color */
	background-color: var(--mainColor);
	color: var(--whiteColor);
	/* display */
	display: flex;
	align-items: center;
	/* font */
	font-size: 0.9rem;
	letter-spacing: 1px;
	/* layout */
	border: none;
	border-radius: 0.4rem;
	padding: 0.9rem 1rem;
	cursor: pointer;

	&:hover {
		background-color: var(--buttonHoverColor);
		transition: all 0.2s ease-in-out;
	}
	@media (max-width: 600px) {
		padding: 1rem;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		font-size: 0.55rem;
		padding: 0.5rem 0.5rem;
	}
`;

export default function Button({ children, link }) {
	return (
		<a href={link} target='_blank' rel='noopener noreferrer'>
			<ButtonTheme>
				{children} <ArrowForwardIosIcon fontSize='10px' sx={{ marginLeft: ".5rem" }} />
			</ButtonTheme>
		</a>
	);
}
