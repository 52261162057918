import React from "react";
import "./App.css";

// components
import Hero from "./components/home/Hero";
import Cards from "./components/home/Cards";

function App() {
	return (
		<>
			<Hero />
			<Cards />
		</>
	);
}

export default App;
